import React from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'

import {
  spacers,
  palette,
  titleStyles,
  descriptionStyles,
} from '../styles/theme'
import { device } from '../device'
import Layout from '../components/layout'
import SEO from '../components/seo'

const Post = styled.article`
  margin: 0;
  margin-bottom: ${spacers.spacer6};
  padding: 0;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  @media ${device.intermediate} {
    margin-right: ${props => (props.spacer ? spacers.spacer6 : 0)};
    margin-bottom: ${spacers.spacer6};
    width: 350px;
  }
  @media (min-width: 1200px) {
    margin-right: ${spacers.spacer6};
  }
`

const ImageLink = styled(props => <Link {...props} />)`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  width: 100%;
  height: 270px;
  display: block;
  text-decoration: none;
  box-shadow: none;
  cursor: pointer;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const PostImage = styled(props => <Img {...props} />)`
  width: 100%;
  height: 100%;
  > picture > img,
  img,
  * {
    margin: 0;
    padding: 0;
  }
`

const PostHeader = styled.header`
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  display: flex;
  flex-direction: column;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
`

const PostTitle = styled.h3`
  margin: 0;
  padding: 0;
`

const PostLink = styled(props => <Link {...props} />)`
  ${titleStyles}
  margin: 0;
  padding: 0;
  color: ${palette.color6};
  text-decoration: none;
  box-shadow: none;
`

const PostSmall = styled.small`
  margin-bottom: ${spacers.spacer1};
  font-family: 'Jorge-Luis-Cafe-Cafe';
  font-size: 18px;
  line-height: 1;
  color: ${palette.color4};
`

const PostDescription = styled.p`
  ${descriptionStyles}
  margin: 0;
  margin-bottom: ${spacers.spacer1};
  padding: 0;
  text-align: left;
  @media ${device.intermediate} {
    margin-bottom: ${spacers.spacer3};
  }
  @media ${device.web} {
  }
`

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout isBlog location={location} title={siteTitle}>
      <SEO title="All posts" />
      {posts.map(({ node }, index) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <Post key={node.fields.slug} spacer={index % 2 === 0}>
            <PostSmall>{`${node.frontmatter.date} - ${node.fields.readingTime.text}`}</PostSmall>
            <ImageLink to={node.fields.slug}>
              <PostImage
                fluid={node.frontmatter.image.childImageSharp.fluid}
                alt={`Image of ${title}`}
              />
            </ImageLink>
            <PostHeader>
              <PostTitle>
                <PostLink to={node.fields.slug}>{title}</PostLink>
              </PostTitle>
            </PostHeader>
            <PostDescription
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description.trim() || node.excerpt,
              }}
            />
          </Post>
        )
      })}
    </Layout>
  )
}

const PostShape = PropTypes.shape({
  node: PropTypes.shape({
    excerpt: PropTypes.string.isRequired,
    fields: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      readingTime: PropTypes.shape({
        text: PropTypes.string.isRequired,
      }),
    }),
    frontmatter: {
      title: PropTypes.string.isRequired,
      date: PropTypes.string.isRequired,
      description: PropTypes.string,
      image: PropTypes.object,
    },
  }),
})

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.arrayOf(PostShape),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/blog/"} }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 1204) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default BlogIndex
